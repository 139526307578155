// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-empty-jsx": () => import("./../../../src/pages/empty.jsx" /* webpackChunkName: "component---src-pages-empty-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-page-details-jsx": () => import("./../../../src/pages/index-page/Details.jsx" /* webpackChunkName: "component---src-pages-index-page-details-jsx" */),
  "component---src-pages-index-page-features-jsx": () => import("./../../../src/pages/index-page/Features.jsx" /* webpackChunkName: "component---src-pages-index-page-features-jsx" */),
  "component---src-pages-index-page-info-jsx": () => import("./../../../src/pages/index-page/Info.jsx" /* webpackChunkName: "component---src-pages-index-page-info-jsx" */),
  "component---src-pages-index-page-intro-jsx": () => import("./../../../src/pages/index-page/Intro.jsx" /* webpackChunkName: "component---src-pages-index-page-intro-jsx" */),
  "component---src-pages-index-page-left-block-jsx": () => import("./../../../src/pages/index-page/LeftBlock.jsx" /* webpackChunkName: "component---src-pages-index-page-left-block-jsx" */),
  "component---src-pages-index-page-manage-jsx": () => import("./../../../src/pages/index-page/Manage.jsx" /* webpackChunkName: "component---src-pages-index-page-manage-jsx" */),
  "component---src-pages-index-page-organize-jsx": () => import("./../../../src/pages/index-page/Organize.jsx" /* webpackChunkName: "component---src-pages-index-page-organize-jsx" */),
  "component---src-pages-index-page-photo-jsx": () => import("./../../../src/pages/index-page/Photo.jsx" /* webpackChunkName: "component---src-pages-index-page-photo-jsx" */),
  "component---src-pages-index-page-right-block-jsx": () => import("./../../../src/pages/index-page/RightBlock.jsx" /* webpackChunkName: "component---src-pages-index-page-right-block-jsx" */),
  "component---src-pages-index-page-sign-up-banner-jsx": () => import("./../../../src/pages/index-page/SignUpBanner.jsx" /* webpackChunkName: "component---src-pages-index-page-sign-up-banner-jsx" */),
  "component---src-pages-index-page-start-text-jsx": () => import("./../../../src/pages/index-page/StartText.jsx" /* webpackChunkName: "component---src-pages-index-page-start-text-jsx" */),
  "component---src-pages-index-page-stay-connected-jsx": () => import("./../../../src/pages/index-page/StayConnected.jsx" /* webpackChunkName: "component---src-pages-index-page-stay-connected-jsx" */),
  "component---src-pages-premium-jsx": () => import("./../../../src/pages/premium.jsx" /* webpackChunkName: "component---src-pages-premium-jsx" */),
  "component---src-pages-premium-page-info-jsx": () => import("./../../../src/pages/premium-page/Info.jsx" /* webpackChunkName: "component---src-pages-premium-page-info-jsx" */),
  "component---src-pages-premium-page-premium-features-jsx": () => import("./../../../src/pages/premium-page/PremiumFeatures.jsx" /* webpackChunkName: "component---src-pages-premium-page-premium-features-jsx" */),
  "component---src-pages-premium-page-premium-intro-jsx": () => import("./../../../src/pages/premium-page/PremiumIntro.jsx" /* webpackChunkName: "component---src-pages-premium-page-premium-intro-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

